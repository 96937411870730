@import "./theme.scss";
@import "@ng-select/ng-select/themes/material.theme.css";


:root {
  --bs-primary: #ff5722; /* Replace with your custom color */
}

.mt-0 {
  margin-top: 0;
}

/* All delay classes will take 2x longer to start */
:root {
  --animate-delay: 2s;
}
.validationError::after {
  content: "** Required Field **";
  font-style: italic;
  color: #ff5722;
  font-size: 0.7rem;
}

.validationErrorMsg {
  content: "** Required Field **";
  font-style: italic;
  color: #ff5722;
  font-size: 0.7rem;
}

.validationErrorEmail::after {
  content: "** Not a valid email address **";
  font-style: italic;
  color: #ff5722;
  font-size: 0.7rem;
}

/* Max length error message */
.validationErrorMaxLength::after {
  content: "**Length cannot exceed 250 characters **";
  font-style: italic;
  color: #ff5722;
  font-size: 0.7rem;
}

.validationLength {
  font-style: italic;
  color: #ff5722;
  font-size: 0.7rem;
}

.paging-buttons-style:hover {
  cursor: pointer;
  color: var(--color-basic-100) !important;
  background-color: var(--color-primary-600) !important;
}

.required-style::after {
  content: "*";
  color: red;
  margin-left: 0.1rem;
}

.expired-list {
  background-color: #ce8e45;
  color: #3d0207;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem #ff572236;
}

.table thead th {
  background-color: #f8f8f8;
  color: #000;
  box-shadow: none;
  border-bottom: none;
  vertical-align: middle;
}

.table td,
.table th {
  vertical-align: middle;
}

:focus {
  outline: none !important;
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: none !important;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

a {
  background-color: transparent !important;
  cursor: pointer;
  &:hover {
    color: #ff5722 !important;
  }
}

.form-control {
  border-radius: 4px !important;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
  #OrderItem {
    max-width: 70vw;
  }

  .table thead th {
    font-size: 12px;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  #OrderItem {
    max-width: 70vw;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 1199px) {
  #OrderItem {
    max-width: 80vw;
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 1200px) {
  #OrderItem {
    max-width: 30vw;
  }
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}




html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



.ng-select .ng-value {
  background-color: #ff5722 !important;
  color: #ffffff !important;
  border-radius: 4px;
}

/* Style for the dropdown options */
.ng-select .ng-dropdown-panel .ng-option {
  background-color: #ffffff !important;  /* White background for options */
  color: #000000 !important;             /* Black text for options */
}

/* Optional: Change background color when hovering over options */
.ng-select .ng-dropdown-panel .ng-option:hover {
  background-color: #f1f1f1 !important;  /* Light gray hover effect */
  color: #000000 !important;
}

/* Optional: Highlight selected options differently in the dropdown */
.ng-select .ng-dropdown-panel .ng-option-selected {
  background-color: #FFEEE9 !important;  /* Orange background for selected option */
  color: #000000 !important;
}

// label {
//   font-weight: 600 !important;
// }






