* {
  font-family: "Roboto Regular", sans-serif;
}

$primary: #ff5722;
$secondary: #727272;
$ternary: #e1e1e1;
$neutral: #989898;

.font-weight-bold {
  font-weight: 600 !important;
}

//All Font
h4 {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  line-height: 1rem !important;

  @media (max-width: 576px) {
    font-size: 1.3rem !important;
  }
}

h5 {
  font-size: 1.25rem !important;
  margin-bottom: 0.4rem !important;

  @media (max-width: 1200px) {
    font-size: 1.1rem !important;
  }
}

p {
  font-size: 1rem;

  @media (max-width: 992px) {
    font-size: 0.9rem !important;
  }

  @media (max-width: 576px) {
    font-size: 0.8rem !important;
  }
}

.active-list {
  background-color: #c8e6c9 !important;
  color: #2a602c !important;
}

.inactive-list {
  background-color: #ffbdc2 !important;
  color: #8c1720 !important;
}

//box-shadow
.shadow-custom {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.shadow-custom:hover {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

//Modal
.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 5.25rem);
}

.modal-content {
  width: 100%;
  max-width: 31.25rem;
  max-height: 40.25rem;
}

//bootstrap button
.btn-primary {
  padding-block: 0.4rem !important;
  background-color: #ff5722 !important;
  color: #fff !important;
  border: 1px solid #ff5722 !important;
  border-radius: 4px !important;

  &:hover {
    background-color: #fc501c !important;
    color: #fff !important;
    border: 1px solid #fc501c !important;
  }

  &:focus {
    background-color: #fc501c !important;
    color: #fff !important;
    border: 1px solid #fc501c !important;
  }
}

.btn-success {
  padding-block: 0.4rem !important;
  background-color: #00d690 !important;
  color: #fff !important;
  border: 1px solid #00d690 !important;
  border-radius: 4px !important;

  &:hover {
    background-color: #00d690 !important;
    color: #fff !important;
    border: 1px solid #00d690 !important;
  }

  &:focus {
    background-color: #00d690 !important;
    color: #fff !important;
    border: 1px solid #00d690 !important;
  }
}

//bootstrap button
.btn-primary-outline {
  padding-block: 0.4rem !important;
  background-color: transparent !important;
  font-weight: 500 !important;
  border: 1px solid #ff5722 !important;
  color: #ff5722 !important;
  border-radius: 4px !important;

  &:hover {
    background-color: #ff5722 !important;
    color: #fff !important;
  }

  &:focus {
    background-color: #ff5722 !important;
    color: #fff !important;
  }
}

.btn-primary-outline:hover svg,
.btn-primary-outline:focus svg,
.btn-primary-outline:active svg{
  fill: #fff !important;
};

.btn-primary:disabled {
  background-color: #ffcbba !important;
  color: #ffffff;
  cursor: not-allowed;
  border: 1px solid #ffcbba !important;
}

.animation {
  animation: slideInFromTop 0.6s ease-out, fadeIn 0.5s ease-in;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation-toTop {
  animation: slideInFromBottom 0.6s ease-out, fadeIn 0.5s ease-in;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation-popup {
  animation: popupFromCenter 0.5s ease-out, fadeIn 0.7s ease-in;
}

@keyframes popupFromCenter {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation-slideLeft {
  animation: slideInFromLeft 0.8s ease-out, fadeIn 0.5s ease-in;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation-slideRight {
  animation: slideInFromRight 0.8s ease-out, fadeIn 0.5s ease-in;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.custom-scrollbar {
  ::-webkit-scrollbar {
    width: 5px;
    transition: all 0.3s ease;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ff5722;
    border-radius: 5px !important;
    transition: all 0.3s ease;
  }

  ::-webkit-scrollbar-thumb:hover {
    width: 10px;
    background-color: #e64a19;
    border-radius: 10px;
    cursor: pointer;
  }
}

@import "../node_modules/bootstrap/scss/bootstrap.scss";
